import React from 'react';
import PropTypes from 'prop-types';
import Alert from "@mui/material/Alert";
import {useAuth0} from "@auth0/auth0-react";

// @ts-ignore
export default function LoginRequiredAlert({ apiCall }) {
    const {
        loginWithPopup
    } = useAuth0();

    const handleLoginAgain = async () => {
        console.log("[LoginRequiredAlert] handleLoginAgain")
        try {
            await loginWithPopup();
        } catch (error: unknown) {
            console.log("[LoginRequiredAlert][ERROR] Error occurred during handleLoginAgain")
            return Promise.reject(error)
        }
        apiCall();
    };

    const handle = (e: React.UIEvent<any, any>, fn: () => Promise<void>) => {
        e.preventDefault();
        fn().then(() => {});
    };

    return (
        <Alert color="warning">
            You need to{" "}
            <a
                href="#/"
                className="alert-link"
                onClick={(e) => handle(e, handleLoginAgain)}
            >
                log in again
            </a>
        </Alert>
    );
}

LoginRequiredAlert.propTypes = {
    apiCall: PropTypes.func,
};
