import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {useAuth0} from "@auth0/auth0-react";
import {Avatar, createTheme, ListItemIcon, ListItemText, ThemeProvider} from "@mui/material";

export default function PrimarySearchAppBar() {
    const {
        user, isAuthenticated, loginWithRedirect, logout,
    } = useAuth0();

    const logoutWithRedirect = () => logout({
        logoutParams: {
            returnTo: window.location.origin,
        }
    });

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);

    Boolean(mobileMoreAnchorEl);
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}>
            {isAuthenticated && user && (
                <MenuItem component="a" href="/profile">
                    <ListItemIcon>
                        <Avatar alt="Profile" src={user.picture} sx={{width: 30, height: 30}}/>
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                </MenuItem>
            )}
            {isAuthenticated && (
                <MenuItem onClick={() => logoutWithRedirect()}>
                    <ListItemIcon>
                        <AccountCircle fontSize="large"/>
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            )}
            {!isAuthenticated && (
                <MenuItem onClick={() => loginWithRedirect()}>
                    <ListItemIcon>
                        <AccountCircle fontSize="large"/>
                    </ListItemIcon>
                    <ListItemText>Login</ListItemText>
                </MenuItem>
            )}
        </Menu>
    );

    const logoFont = createTheme({
        typography: {
            fontFamily: "'Pacifico'",
        },
    });

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar sx={{justifyContent: "space-between"}}>
                    <Box>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            href="/">
                            <HomeIcon fontSize="large"/>
                        </IconButton>
                        {isAuthenticated && (
                            <IconButton size="large" aria-label="show 17 new notifications" color="inherit"
                                        href="/alerts">
                                <Badge badgeContent={17} color="error">
                                    <NotificationsIcon fontSize="large"/>
                                </Badge>
                            </IconButton>
                        )}
                    </Box>
                    <ThemeProvider theme={logoFont}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            TurtleTrace
                        </Typography>
                    </ThemeProvider>
                    <Box>
                        {isAuthenticated && (
                            <IconButton size="large" aria-label="show 4 new mails" color="inherit" href="/assignments">
                                <Badge badgeContent={4} color="error">
                                    <MailIcon fontSize="large"/>
                                </Badge>
                            </IconButton>
                        )}
                        {!isAuthenticated && (
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="user is not logged in"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit">
                                <AccountCircle fontSize="large"/>
                            </IconButton>
                        )}
                        {isAuthenticated && user && (
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit">
                                <Avatar alt="Profile" src={user.picture} sx={{width: 40, height: 40}}/>
                            </IconButton>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMenu}
        </Box>
    );
}