import * as React from 'react';
import {useEffect} from 'react';

import Box from "@mui/material/Box";
import {alpha, LinearProgress, Snackbar, useTheme} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import AssignmentList from "../components/AssignmentList";
import EventList from "../components/EventList";

// Auth
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {ApiAuth} from "../types/ApiAuth";
import {getConfig} from "../config";

export const AssignmentsComponent = () => {
    const {audience} = getConfig()

    const {
        user,
        getIdTokenClaims,
        getAccessTokenSilently
    } = useAuth0();

    const apiAuth = new ApiAuth(
        user,
        audience,
        getAccessTokenSilently
    )

    const [roles, setRoles] = React.useState<Array<string>>(new Array<string>())
    const [transientError, setTransientError] = React.useState<string | null>(null)

    // TODO: figure out why eslint is so mad about this
    // eslint-disable-next-line
    useEffect(() => { getRoles(); }, [])

    const getRoles = (): void => {
        console.log("getRoles")
        getIdTokenClaims()
            .then(async (claims) => {
                // To get roles on the token a custom action had to be added
                // https://auth0.com/docs/manage-users/access-control/sample-use-cases-actions-with-authorization#add-user-roles-to-tokens
                // This means that RBAC is needed for all users
                // Roles:
                // 'viewer' : Read-Only user associated with one org
                // 'maintainer' : Read-Write user associated with one org
                if (claims) {
                    let roles = claims["https://turtletrace.no/roles"];
                    setRoles(roles)
                } else {
                    setRoles(new Array<string>())
                }
            })
            .catch((error) => {
                setRoles(new Array<string>())
                if (error.error !== undefined) {
                    let message = "WARN " + error.error;
                    console.log(message)
                    setTransientError(message);
                } else {
                    let message = "WARN " + error.message;
                    console.log(message)
                    setTransientError(message);
                }
            })
            .finally(() => {
                console.log("Roles of user : " + JSON.stringify(roles))
            })
    }

    // Filtering
    // https://mui.com/x/react-data-grid/filtering/
    // NOTE: Explicitly avoiding the React optimization of not setting something to its current value
    // When the user erases the quick filter field filterTerm isn't reset, so the user cannot filter again for the
    // same term because React optimizes it out. Holding the terms in an object subverts the optimization.
    const [filterTerm, setFilterTerm] = React.useState({assignmentID: "", buyerName: ""})

    function setSelectedAssignmentID(assignmentID: string) {
        setFilterTerm({assignmentID: assignmentID, buyerName: ""})
    }

    function setSelectedBuyerName(buyerName: string) {
        setFilterTerm({assignmentID: "", buyerName: buyerName})
    }

    const [filterEventTerm, setFilterEventTerm] = React.useState({assignmentID: ""})

    function setSelectedEventAssignmentID(assignmentID: string) {
        setFilterEventTerm({assignmentID: assignmentID})
    }

    // For better navigation on mobile: https://mui.com/material-ui/react-bottom-navigation/
    const theme = useTheme();

    return (
        <>
            <Grid container spacing={2}>
                {/*xs, extra-small: 0px*/}
                {/*sm, small: 600px*/}
                {/*md, medium: 900px*/}
                {/*lg, large: 1200px*/}
                {/*xl, extra-large: 1536px*/}
                <Grid xl={4} lg={4} md={6} sm={12} xs={12}>
                    <EventList
                        selectedAssignment={setSelectedAssignmentID}
                        selectedBuyer={setSelectedBuyerName}
                        filterTerm={filterEventTerm}
                    />
                </Grid>
                <Grid xl={8} lg={8} md={6} sm={12} xs={12}>
                    <AssignmentList
                        apiAuth={apiAuth}
                        roles={roles}
                        selectedAssignment={setSelectedEventAssignmentID}
                        filterTerm={filterTerm}
                    />
                </Grid>
            </Grid>
            <Snackbar
                open={transientError != null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                autoHideDuration={6000}
                message={transientError}
                onClose={() => setTransientError(null)}
                ContentProps={{
                    sx: {
                        backgroundColor: alpha(theme.palette.warning.light, 0.9)
                    }
                }}
            />
        </>
    );
};

export default withAuthenticationRequired(AssignmentsComponent, {
    onRedirecting: () => (
        <Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>
    ),
});
