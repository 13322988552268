import {AssignmentEvent} from "./AssignmentEvent";

export type Assignment = {
    id: string;
    platform: string;
    buyerName: string;
    title: string;
    announcedDate: string;
    events: AssignmentEvent[];
}

export function formatDate(date: Date) {
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear();
    return `${year}-${month}-${day}`
}

export function announcedDate(a: Assignment): string {
    const date = new Date(a.announcedDate);
    return formatDate(date);
}

export function hasAppliedFor(a: Assignment): boolean {
    let event = eventByType(a.events, "Applied");
    return !!event
}

export function linkToNewEvent(a: Assignment): string | null {
    let event = eventByType(a.events, "New");
    return event?.url || null
}

function eventByType(events: Array<AssignmentEvent>, eventType: string): AssignmentEvent | undefined {
    return events.find((event) => (event.eventType === eventType));
}