import React from 'react';
import PropTypes from 'prop-types';
import Alert from "@mui/material/Alert";
import {useAuth0} from "@auth0/auth0-react";

// @ts-ignore
export default function ConsentRequiredAlert({ apiCall }) {
    const {
        getAccessTokenWithPopup
    } = useAuth0();

    const handleConsent = async (): Promise<void> => {
        console.log("[ConsentRequiredAlert] handleConsent")
        try {
            await getAccessTokenWithPopup();
        } catch (error: unknown) {
            console.log("[ConsentRequiredAlert][ERROR] Error occurred during handleConsent")
            return Promise.reject(error)
        }
        apiCall();
    };

    const handle = (e: React.UIEvent<any, any>, fn: () => Promise<void>) => {
        e.preventDefault();
        fn().then(() => {});
    };

    return (
        <Alert color="warning">
            You need to{" "}
            <a
                href="#/"
                className="alert-link"
                onClick={(e) => handle(e, handleConsent)}
            >
                consent to get access to the api
            </a>
        </Alert>
    );
}

ConsentRequiredAlert.propTypes = {
    apiCall: PropTypes.func,
};
