import * as React from 'react';

import {Container} from "reactstrap";

import {Avatar, Box, LinearProgress} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";

export const ProfileComponent = () => {
    const {user} = useAuth0();

    return (
        <Container>
            {user && (
                <Grid container spacing={2} columns={2} justifyContent="center" alignItems="center">
                    <Grid xs={2} sm={1}>
                        <Avatar
                            alt={user.name}
                            src={user.picture}
                            sx={{width: 100, height: 100}}
                        />
                    </Grid>
                    <Grid xs={2} sm={1}>
                        <h2>{user.name}</h2>
                    </Grid>
                    <Grid xs={2} sm={1}>
                        <h3>Email</h3>
                    </Grid>
                    <Grid xs={2} sm={1}>
                        {user.email}
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => (
        <Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>
    ),
});
