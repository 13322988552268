import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DangerousIcon from "@mui/icons-material/Dangerous";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import BugReportIcon from "@mui/icons-material/BugReport";
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import * as React from "react";
import {blue, green, orange, yellow} from "@mui/material/colors";

export function getIcon(eventType: string | undefined | null) {
    if (eventType === "New") return <AutoAwesomeIcon/>;
    if (eventType === "Deadline") return <NotificationsActiveIcon/>;
    if (eventType === "Update") return <TipsAndUpdatesOutlinedIcon/>;
    if (eventType === "Reply") return <QuestionAnswerIcon/>;
    if (eventType === "Applied") return <CloudUploadIcon/>;
    if (eventType === "Rejected") return <DangerousIcon/>;
    if (eventType === "Assigned") return <HowToRegIcon/>;
    if (eventType === "Qualified") return <VerifiedRoundedIcon/>;
    return <BugReportIcon/>
}

export function getColor(eventType: string | undefined | null) {
    if (eventType === "New") return "success.light";
    if (eventType === "Deadline") return "warning.light";
    if (eventType === "Update") return "secondary.main";
    if (eventType === "Reply") return "secondary.light";
    if (eventType === "Applied") return "success.light";
    if (eventType === "Rejected") return "error.dark";
    if (eventType === "Assigned") return "success.main";
    if (eventType === "Qualified") return "success.light";
    return "error.dark";
}

export function getLink(url: string | null): string | undefined {
    return url ? url : undefined;
}

function makeFavIconUrl(domain: string) {
    return "https://s2.googleusercontent.com/s2/favicons?domain=" + domain
}

export function getBuyerIcon(buyer: string) {
    if (buyer === "Ruter As") return makeFavIconUrl("ruter.no")
    if (buyer === "Registerenheten i Brønnøysund") return makeFavIconUrl("brreg.no")
    if (buyer === "Stortinget") return makeFavIconUrl("www.stortinget.no")
    if (buyer === "Norsk rikskringkasting AS") return makeFavIconUrl("nrk.no")
    if (buyer === "Integrerings- og mangfoldsdirektoratet (IMDi)") return makeFavIconUrl("imdi.no")
    if (buyer === "Aktieselskapet Vinmonopolet") return makeFavIconUrl("vinmonopolet.no")
    if (buyer === "AS Vinmonopolet") return makeFavIconUrl("vinmonopolet.no")
    if (buyer === "KS (Kommunesektorens organisasjon)") return makeFavIconUrl("ks.no")
    if (buyer === "Norsk Helsenett SF") return makeFavIconUrl("nhn.no")
    if (buyer === "NORDRE FOLLO KOMMUNE") return makeFavIconUrl("nordrefollo.kommune.no")
    if (buyer === "Stavanger kommune") return makeFavIconUrl("stavanger.kommune.no")
    if (buyer === "Sjøfartsdirektoratet") return makeFavIconUrl("sdir.no")
    if (buyer === "Direktoratet for forvaltning og økonomistyring (DFØ)") return makeFavIconUrl("dfo.no")
    if (buyer === "Flytoget AS") return makeFavIconUrl("flytoget.no")
    if (buyer === "Ålesund kommune") return makeFavIconUrl("alesund.kommune.no")
    if (buyer === "AVINOR AS") return makeFavIconUrl("avinor.no")
    if (buyer === "Bane NOR SF") return makeFavIconUrl("banenor.no")
    if (buyer === "Entur AS") return makeFavIconUrl("entur.no")
    if (buyer === "Skatteetaten") return makeFavIconUrl("skatteetaten.no")
    if (buyer === "Mattilsynet") return makeFavIconUrl("mattilsynet.no")
    if (buyer === "DDV") return makeFavIconUrl("ddv.no")
    if (buyer === "UNIVERSITETET I BERGEN") return makeFavIconUrl("uib.no")
    if (buyer === "BrainBase AS") return makeFavIconUrl("brainbase.no")

    if (buyer === "Meteorologisk Institutt") return makeFavIconUrl("met.no")
    if (buyer === "STATSBYGG") return makeFavIconUrl("statsbygg.no")
    if (buyer === "ARBEIDS- OG VELFERDSETATEN") return makeFavIconUrl("nav.no")
    if (buyer === "Inventura AS") return makeFavIconUrl("inventura.no")
    if (buyer === "IKT Valdres IKS") return undefined // makeFavIconUrl("iktvaldres.no")

    if (buyer.toLowerCase().includes("direktorat")) return undefined
    if (buyer.toLowerCase().includes("fylke")) return undefined
    if (buyer.toLowerCase().includes("kommune")) return undefined

    console.log(`WARN favicon not found for '${buyer}'`)
    return undefined
    // if (buyer === "") return makeFavIconUrl("")
}

export function getBuyerBackgroundColor(buyer: string) {
    if (buyer.toLowerCase().includes("direktorat")) return blue[500]
    if (buyer.toLowerCase().includes("fylke")) return yellow[700]
    if (buyer.toLowerCase().includes("kommune")) return orange[700]
    return green[500];
}