import React, {useEffect, useState} from "react";

import Grid from "@mui/material/Unstable_Grid2";
import {Box, LinearProgress, TextField} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";

import {findCompany} from "../api/BrregApi";
import {OrgReg} from "../types/OrgReg";
import {findPurchasingSystems} from "../api/AssignmentsApi";

import {DoffinSearchResult} from "../types/DoffinSearchResult";
import DoffinSearchResultList from "../components/DoffinSearchResultList";
import Typography from "@mui/material/Typography";

export default function Home() {
    const [result, setResult] = React.useState<OrgReg | null>(null);
    const [searchResult, setSearchResult] = React.useState<DoffinSearchResult | null>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchInProgress, setSearchInProgress] = React.useState<boolean>(false);


    useEffect(() => {
        if (searchQuery) {
            setSearchResult(null)
            let cleanQuery = searchQuery.split(/\s+/).join('')
            findCompany(cleanQuery).then((data) => {
                setResult(data)
            });
        } else {
            setResult(null)
        }
    }, [searchQuery])

    useEffect(() => {
        if (result && result.naeringskode1) {
            setSearchInProgress(true)
            findPurchasingSystems(result.naeringskode1)
                .then((data) => {
                    setSearchResult(data)
                })
                .finally(() => {
                    setSearchInProgress(false)
                });
        }
    }, [result]);

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" marginTop="10px">
            {result && (
                <Grid xl={5} lg={5} md={12} sm={12} xs={12}>
                    <Typography variant="h4">
                        {result?.navn}
                    </Typography>
                    <Typography variant="body1">
                        {result && result.naeringskode1 && (
                            <>
                                {result.naeringskode1.beskrivelse} ({result.naeringskode1.kode})
                            </>
                        )}
                        {result && result.forretningsadresse && (
                            <>
                                {result.forretningsadresse.postnummer} {result.forretningsadresse.poststed}
                            </>
                        )}
                        {result && result.slettedato && (
                            <>
                                Deleted: {result.slettedato}
                            </>
                        )}
                    </Typography>
                </Grid>
            )}
            <Grid xl={2} lg={2} md={12} sm={12} xs={12}>
                <Box sx={{
                    display: 'flex',
                    alignContent: 'stretch',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '2px'
                }}>
                    <form>
                        <TextField
                            id="search-bar"
                            className="text"
                            onInput={(e) => {
                                let field = (e.target as HTMLInputElement)
                                setSearchQuery(field.value);
                            }}
                            label="Organization number"
                            variant="outlined"
                            placeholder="Search..."
                            fullWidth
                        />
                    </form>
                    <IconButton type="submit" aria-label="search">
                        <SearchIcon fontSize="large"/>
                    </IconButton>
                </Box>
            </Grid>
            {result && (
                <Grid xl={5} lg={5} md={12} sm={12} xs={12}>
                    <Typography variant="body1" sx={{fontStyle: 'italic'}}>
                        {result.vedtektsfestetFormaal && (
                            <>
                                "{result?.vedtektsfestetFormaal.join(" ")}"
                            </>
                        )}
                    </Typography>
                </Grid>
            )}
            {searchInProgress && (
                <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
            )}
            {result && searchResult && (
                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                    <DoffinSearchResultList result={searchResult}/>
                </Grid>
            )}
        </Grid>
    );
}
