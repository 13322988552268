import React from 'react';
import PropTypes from 'prop-types';
import Alert from "@mui/material/Alert";

export default function WarningAlert({warningText}: {
    warningText: string,
}) {
    return (
        <Alert color="warning">
            {warningText}
        </Alert>
    );
}

WarningAlert.propTypes = {
    warningText: PropTypes.string,
};
