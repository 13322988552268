import {User} from "@auth0/auth0-react";
import {Auth0ContextInterface} from "@auth0/auth0-react/src/auth0-context";

type AccessTokenSilently = Auth0ContextInterface["getAccessTokenSilently"];

export interface ApiAuthInterface {
    authenticatedUser: User | undefined;
    audience: string | undefined;
    accessTokenSilently: AccessTokenSilently | undefined
}

export class ApiAuth implements ApiAuthInterface {
    constructor(
        authenticatedUser: User | undefined,
        audience: string | undefined,
        accessTokenSilently: AccessTokenSilently | undefined
    ) {
        this.authenticatedUser = authenticatedUser;
        this.audience = audience;
        this.accessTokenSilently = accessTokenSilently;
    }

    accessTokenSilently: AccessTokenSilently | undefined;
    audience: string | undefined;
    authenticatedUser: User | undefined;
}

export class MockApiAuth implements ApiAuthInterface {
    accessTokenSilently: AccessTokenSilently | undefined;
    audience: string | undefined;
    authenticatedUser: User | undefined;
}
