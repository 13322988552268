import * as React from 'react';

import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {AppState, Auth0Provider, Auth0ProviderOptions} from "@auth0/auth0-react";
import { createBrowserHistory} from "history";
import {getConfig} from "./config";

const history = createBrowserHistory()

const onRedirectCallback = (appState?: AppState): void => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
  // default implementation - what does this mean?
  // window.history.replaceState(
  //     {},
  //     document.title,
  //     appState?.returnTo || window.location.pathname
  // );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

export interface ProviderOptions extends Auth0ProviderOptions {
}

const providerConfig : ProviderOptions = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

let rootElement = document.getElementById('root');
if (rootElement != null) {
  const root = createRoot(rootElement);
  root.render(
      <Auth0Provider
          {...providerConfig}
      >
        <App/>
      </Auth0Provider>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
