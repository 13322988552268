import * as React from 'react';

// Widgets
import {DataGrid, GridColDef, GridRowsProp, GridToolbar} from '@mui/x-data-grid';
import {Avatar, Box, Link, Tooltip, useTheme} from "@mui/material";

// Icons
// Types
import {DoffinSearchResult, Hit} from "../types/DoffinSearchResult";
import useMediaQuery from "@mui/material/useMediaQuery";
import {getBuyerBackgroundColor, getBuyerIcon} from "./EventIcon";
import {grey} from "@mui/material/colors";

export const MOBILE_COLUMNS = {
    id: false,
    buyer: true,
    heading: true,
    description: false,
    locationId: false,
    estimatedValue: false,
    type: false,
    issueDate: false,
    deadline: false
};

export const ALL_COLUMNS = {
    id: false,
    buyer: true,
    heading: true,
    description: true,
    locationId: true,
    estimatedValue: true,
    type: true,
    issueDate: true,
    deadline: true
};

export default function DoffinSearchResultList(props: { result: DoffinSearchResult; }) {

    const result: DoffinSearchResult = props.result

    function getAmount(hit: Hit) {
        if (hit.estimatedValue) {
            return `${hit.estimatedValue.amount} ${hit.estimatedValue.currencyCode}`
        }
        return "";
    }

    function getContractType(hit: Hit) {
        if (hit.type === "DYNAMIC_PURCHASING_SCHEME") return "DPS"
        if (hit.type === "QUALIFICATION_SCHEME") return "QS"
        return hit.type;
    }

    const rows = result.hits.map((hit) =>
        ({
            id: hit.id,
            buyer: hit.buyer[0].name,
            heading: hit.heading,
            description: hit.description,
            locationId: hit.locationId,
            estimatedValue: getAmount(hit),
            type: getContractType(hit),
            issueDate: new Date(hit.issueDate).toISOString().split('T')[0],
            deadline: new Date(hit.deadline).toISOString().split('T')[0]
        })) as GridRowsProp;

    function getDoffinLink(id: string) {
        return "https://doffin.no/notices/" + id;
    }

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'Doffin id', flex: 0.5, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.heading}>
                        <Link href={getDoffinLink(params.row.id)} underline="always" target="_blank">
                            {params.row.id}
                        </Link>
                    </Tooltip>
                )
            }
        },
        {
            field: 'buyer', headerName: 'buyer', flex: 0.2, renderCell: (params) => {
                let buyerName = params.row.buyer
                let favicon = getBuyerIcon(buyerName);
                let backgroundColor = getBuyerBackgroundColor(buyerName);
                return (
                    <>
                        {favicon && (
                            <Tooltip title={params.row.buyer}>
                                <Avatar
                                    alt={params.row.buyer}
                                    src={favicon}
                                    sx={{width: 26, height: 26, bgcolor: grey[50]}}
                                    style={{
                                        border: '0.1px solid gray'
                                    }}
                                />
                            </Tooltip>
                        )}
                        {!favicon && (
                            <Tooltip title={params.row.buyer}>
                                <Avatar
                                    alt={params.row.buyer}
                                    sx={{
                                        bgcolor: backgroundColor,
                                        width: 26,
                                        height: 26
                                    }}
                                    style={{
                                        border: '0.1px solid gray'
                                    }}>
                                    {params.row.buyer.charAt(0).toUpperCase()}
                                </Avatar>
                            </Tooltip>
                        )}
                    </>
                )
            }
        },
        {
            field: 'heading', headerName: 'Title', flex: 2, renderCell: (params) => {
                return (
                    <Tooltip title={params.row.heading}>
                        <Link href={getDoffinLink(params.row.id)} underline="always" target="_blank">
                            {params.row.heading}
                        </Link>
                    </Tooltip>
                )
            }
        },
        {field: 'description', headerName: 'description', flex: 2},
        {field: 'locationId', headerName: 'locationId', flex: 0.3},
        {field: 'estimatedValue', headerName: 'estimatedValue', flex: 0.5},
        {field: 'type', headerName: 'type', flex: 0.3},
        {field: 'issueDate', headerName: 'issueDate', flex: 0.4},
        {field: 'deadline', headerName: 'deadline', flex: 0.4},
    ];

    const theme = useTheme();
    const small_screen = useMediaQuery(theme.breakpoints.up("sm"));
    const [columnVisible, setColumnVisible] = React.useState(ALL_COLUMNS);
    const [hideToolbar, setHideToolbar] = React.useState(true);

    React.useEffect(() => {
        const newColumns = small_screen ? ALL_COLUMNS : MOBILE_COLUMNS;
        setColumnVisible(newColumns);
        setHideToolbar(small_screen)
    }, [small_screen]);

    return (
        <Box sx={{width: '100%'}}>
            <DataGrid
                disableColumnFilter={!hideToolbar}
                disableColumnSelector
                disableDensitySelector={!hideToolbar}
                disableColumnMenu={!hideToolbar}
                rows={rows}
                columns={columns}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                initialState={{
                    sorting: {
                        sortModel: [{field: 'issueDate', sort: 'desc'}],
                    },
                    pagination: {paginationModel: {pageSize: 12}},
                }}
                columnVisibilityModel={columnVisible}
                pageSizeOptions={[12, 50, 100]}
            />
        </Box>
    );
}
