import * as React from 'react';

import {Button} from "reactstrap";
import {LinearProgress} from "@mui/material";
import Box from "@mui/material/Box";
import ConsentRequiredAlert from "../components/ConsentRequiredAlert";
import LoginRequiredAlert from "../components/LoginRequiredAlert";

import {getConfig} from "../config";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";

import {getNumberOfAssignments} from "../api/AssignmentsApi";
import WarningAlert from "../components/WarningAlert";
import {ApiAuth} from "../types/ApiAuth";

export const AlertsComponent = () => {
    const {audience} = getConfig()

    const {
        user,
        getAccessTokenSilently
    } = useAuth0();

    const apiAuth = new ApiAuth (
        user,
        audience,
        getAccessTokenSilently
    )

    const [error, setError] = React.useState<string | null>(null)
    const [numAssignments, setNumAssignments] = React.useState<number | null>(null)
    const [numAssignmentSearchInProgress, setNumAssignmentSearchInProgress] = React.useState<boolean>(false);

    const fetchNumAssignments = (): void => {
        console.log("fetchNumAssignments")
        setError(null)
        setNumAssignmentSearchInProgress(true)
        getNumberOfAssignments(apiAuth)
            .then((assignments) => {
                setNumAssignments(assignments)
            })
            .catch((error) => {
                if (error.error !== undefined) {
                    setError(error.error)
                } else {
                    setError(error.message)
                }
            })
            .finally(() => {
                setNumAssignmentSearchInProgress(false)
            })
    }

    return (
        <>
            <div className="mb-5">
                {error === "consent_required" && (
                    <ConsentRequiredAlert apiCall={fetchNumAssignments}/>
                )}
                {error === "login_required" && (
                    <LoginRequiredAlert apiCall={fetchNumAssignments}/>
                )}
                {error && (
                    <WarningAlert
                        warningText={"API error : " + error}/>
                )}
                {!audience && (
                    <WarningAlert
                        warningText="App misconfiguration 'audience missing' - the API is not currently available"/>
                )}
                {(numAssignmentSearchInProgress) && (
                    <Box sx={{width: '100%'}}>
                        <LinearProgress/>
                    </Box>
                )}

                <h1>TurtleTrace API</h1>

                <Button
                    color="primary"
                    className="mt-5"
                    onClick={fetchNumAssignments}
                    disabled={!audience}
                >
                    Ping API
                </Button>
            </div>

            <div className="result-block-container">
                {numAssignments && (
                    <div className="result-block" data-testid="api-result">
                        <h6 className="muted">Number of assignments for tracing org</h6>
                        <p>
                            <span>{numAssignments}</span>
                        </p>
                    </div>)}
            </div>
        </>
    );
};

export default withAuthenticationRequired(AlertsComponent, {
    onRedirecting: () => (
        <Box sx={{width: '100%'}}>
            <LinearProgress/>
        </Box>
    ),
});
